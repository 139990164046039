import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React from 'react'
import { Box, Container, Grid } from 'theme-ui'
import { hubspotFormIds } from '~/common/hubspot'
import Layout from '~/components/layout'
import { PageTitle } from '~/componentsV2/pageTitle'
import { ContactSections } from '~/componentsV2/pages/contact/components/ContactSections'
import { ContanctPageQuery } from '~/componentsV2/pages/contact/types'
import theme from '~/gatsby-plugin-theme-ui'
import { HubspotForm } from '~/utils/hubspotForm'
import { trackContact } from '~/utils/marketing/track'

const ContactPage = () => {
  const location = useLocation()
  const breakpoints = useBreakpoint()

  const query = useStaticQuery<ContanctPageQuery>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "contact" } }) {
        frontmatter {
          title
          description
          sections {
            name
            information
            contact {
              method
              value
            }
            openHours {
              day
              time
            }
          }
        }
      }
    }
  `)

  const {
    frontmatter: { title, sections, description },
  } = query.pageData

  const params = new URLSearchParams(location.search)

  return (
    <Layout headerVariant="default" title="Contact" description={description}>
      <Container sx={{ maxWidth: 1512 }}>
        <Box sx={{ mt: [25, 50] }}>
          <PageTitle title={title} />
        </Box>
        <Grid
          columns={['1fr', '1fr 1fr']}
          gap={[0, 50]}
          sx={{
            py: [50],
            borderTop: ['none', `1px solid #E6E6E6`],
          }}
        >
          <Box sx={{ maxWidth: 476, mb: [theme.space.md] }}>
            <ContactSections sections={breakpoints.xs ? sections : sections.slice(0, 1)} />
          </Box>
          <Box sx={{ mb: 50 }}>
            <HubspotForm
              formId={hubspotFormIds.contact ?? ``}
              onSubmit={() => {
                trackContact()
              }}
            />
          </Box>
          {!breakpoints.xs && (
            <Box sx={{ maxWidth: 435, mb: [theme.space.md] }}>
              <ContactSections sections={sections.slice(1)} />
            </Box>
          )}
        </Grid>
      </Container>
    </Layout>
  )
}

export default ContactPage
